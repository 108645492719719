import {mapMutations} from "vuex";

export default {
	props: {
		data: Object,
		default: null
	},
	mixins: [require('../../../mixins/templateEngine')],
	data() {
		return {
			isLoaded: false,
		}
	},
	methods: {
		...mapMutations({
			donationsLoggerData: 'donation/donationsLoggerData',
		}),
		onImgLoad () {
			this.isLoaded = true;
		}
	},
};

