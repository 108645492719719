export default {
	props: {
		data: Object,
		default: null
	},
	mixins: [require('../../../mixins/templateEngine')],
	computed: {
		header() {
			return this?.data?.header;
		},
	},
};

