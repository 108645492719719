import {mapGetters, mapMutations} from "vuex";

export default {
	data() {
		return {
			showCopyText: false,
			mapShareLogger: {
				fb: 1,
				tg: 2,
				vb: 3,
				tw: 4,
				copy: 5,
			},
			logActionBlock: 2,
			AppIosShare: false,
		};
	},
	props: {
		data: Object,
		default: null
	},
	mixins: [require('../../../../mixins/templateEngine')],
	methods: {
		...mapMutations({
			donationsLoggerData: 'donation/donationsLoggerData',
		}),
		sharing: function (socNet) {
			let urlForShare = '';
			switch (socNet) {
				case 'fb':
					urlForShare = 'http://www.facebook.com/sharer.php?u=' + encodeURIComponent(`https://auto.ria.com${this.$route.path}?utm_source=facebook&utm_medium=share_zsu&utm_campaign=auto_for_zsu&r_audience=smo&r_source=facebook&r_medium=share_zsu&r_campaign=auto_for_zsu`);
					break;
				case 'tg':
					urlForShare = 'https://t.me/share/url?url=' + encodeURIComponent(`https://auto.ria.com${this.$route.path}?utm_source=telegram&utm_medium=share_zsu&utm_campaign=auto_for_zsu&r_audience=smo&r_source=telegram&r_medium=share_zsu&r_campaign=auto_for_zsu`);
					break;
				case 'vb':
					urlForShare = 'viber://forward?text=' + encodeURIComponent(`https://auto.ria.com${this.$route.path}?utm_source=viber&utm_medium=share_zsu&utm_campaign=auto_for_zsu&r_audience=smo&r_source=viber&r_medium=share_zsu&r_campaign=auto_for_zsu`);
					break;
				case 'tw':
					urlForShare = 'https://twitter.com/intent/tweet?url=' + encodeURIComponent(`https://auto.ria.com${this.$route.path}?utm_source=twitter&utm_medium=share_zsu&utm_campaign=auto_for_zsu&r_audience=smo&r_source=twitter&r_medium=share_zsu&r_campaign=auto_for_zsu`);
					break;
			}

			this.donationsLoggerData({ action_block: this.logActionBlock, click_action: this.mapShareLogger[socNet] });
			this.popup(urlForShare);
		},
		popup: function (url) {
			let win = window.open(url, '', 'toolbar=0,status=0,width=600,height=436');
		},
		copy() {
			this.$refs.clone.focus();
			document.execCommand('copy');
			this.showCopyText = true;
			this.donationsLoggerData({ action_block: this.logActionBlock, click_action: this.mapShareLogger['copy'] });
			setTimeout(() => {
				this.showCopyText = false;
			}, 3000)
		}
	},
	computed: {
		...mapGetters({
			isWebView: 'shared/device/isWebView',
		}),
	},
	mounted() {
		if (navigator && navigator.share){
			this.AppIosShare = true;
		}
	},
	components: {
		'webShare': () => import('./webShare.vue'),
	},
};

