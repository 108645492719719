<template>
  <button class="i-button share-btn" type="button" @click="share">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.9249 15.0378C4.2749 15.0378 2.9624 13.7253 2.9624 12.0753C2.9624 10.4253 4.2749 9.11279 5.9249 9.11279C7.5749 9.11279 8.88741 10.4253 8.88741 12.0753C8.88741 13.6878 7.5749 15.0378 5.9249 15.0378ZM5.9249 10.2003C4.9124 10.2003 4.0874 11.0253 4.0874 12.0378C4.0874 13.0503 4.9124 13.8753 5.9249 13.8753C6.9374 13.8753 7.76241 13.0503 7.76241 12.0378C7.76241 11.0628 6.9374 10.2003 5.9249 10.2003Z" fill="#222329"/>
      <path d="M17.9625 9.00019C16.3125 9.00019 15 7.68769 15 6.03769C15 4.38769 16.3125 3.0752 17.9625 3.0752C19.6125 3.0752 20.925 4.38769 20.925 6.03769C20.925 7.68769 19.575 9.00019 17.9625 9.00019ZM17.9625 4.2002C16.95 4.2002 16.125 5.02519 16.125 6.03769C16.125 7.05019 16.95 7.87519 17.9625 7.87519C18.975 7.87519 19.8 7.05019 19.8 6.03769C19.8 5.02519 18.975 4.2002 17.9625 4.2002Z" fill="#222329"/>
      <path d="M17.9625 21.0378C16.3125 21.0378 15 19.7253 15 18.0753C15 16.4253 16.3125 15.1128 17.9625 15.1128C19.6125 15.1128 20.925 16.4253 20.925 18.0753C20.925 19.7253 19.575 21.0378 17.9625 21.0378ZM17.9625 16.2378C16.95 16.2378 16.125 17.0628 16.125 18.0753C16.125 19.0878 16.95 19.9128 17.9625 19.9128C18.975 19.9128 19.8 19.0878 19.8 18.0753C19.8 17.0628 18.975 16.2378 17.9625 16.2378Z" fill="#222329"/>
      <path d="M15.5248 17.5501L7.7998 13.6876L8.32481 12.6001L16.0873 16.4626L15.5248 17.5501Z" fill="#222329"/>
      <path d="M8.32481 11.5126L7.7998 10.4626L15.5248 6.6001L16.0873 7.65009L8.32481 11.5126Z" fill="#222329"/>
    </svg>
    Поділитись
  </button>
</template>

<script>
export default {
  name: "share",
  methods: {
    share() {
      if (navigator && navigator?.share){
        navigator.share({
            url : "https://auto.ria.com/auto-for-zsu/?utm_source=copylink&utm_medium=share_zsu&utm_campaign=auto_for_zsu&r_audience=smo&r_source=copylink&r_medium=share_zsu&r_campaign=auto_for_zsu",
            title:"Я з друзями збираю на авто для ЗСУ",
            description: "І ти можеш нам допомогти! Донейт — це легко! Зроби свій внесок у Перемогу"
          })
          .then(_ => console.log("Дякуємо, що поділився :)"))
          .catch(error =>
              console.log("navigator.share error ", error)
          );
      }
    }
  }
};
</script>

<style>

.share-btn{
    font-size: 18px;
    font-weight: 700;

  }
.share-btn svg{
    margin-top: -2px;
}

.for-zsu.common .share-btn{
    color: #fff;
    border-color: #fff;
}
.for-zsu.common .share-btn svg path{
    fill: currentColor;
}
</style>


