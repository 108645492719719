import { mapMutations } from 'vuex';

export default {
  props: {
    data: Object,
    default: null,
  },
  mixins: [require('../../../mixins/templateEngine')],
  data() {
    return {
      isLoaded: false,
    };
  },
  mounted() {
    window.addEventListener('blur', () => {
      if (document.activeElement instanceof HTMLIFrameElement) {
        const { id, src: url } = document.activeElement;
        const index = Number(id[id.length - 1]);
        this.donationsLoggerData({ action_block: 4, click_action: index + 1, current_url: url });
      }
    });
  },
  methods: {
    ...mapMutations({
      donationsLoggerData: 'donation/donationsLoggerData',
    }),
    onImgLoad() {
      this.isLoaded = true;
    },
  },
};
