<template>
    <div ref="googlePay" />
</template>
<script>
import { mapGetters } from "vuex";

import _laxios from '../../../../../store/_laxios';
export default {
  name: "GooglePay",
  props: {
    totalPrice: {
      type: String,
      default: "100.00",
      required: true,
    },
    showCongratsPopup: {
      type: Function,
      default: () => 1,
    },
  },
  data: () => ({
    googlePayInitData: {},
    config: {
      /**
       * Define the version of the Google Pay API referenced when creating your
       * configuration
       *
       * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#PaymentDataRequest|apiVersion in PaymentDataRequest}
       */
      baseRequest: {
        apiVersion: 2,
        apiVersionMinor: 0,
      },
      /**
       * Identify your gateway and your site's gateway merchant identifier
       *
       * The Google Pay API response will return an encrypted payment method capable
       * of being charged by a supported gateway after payer authorization
       *
       * @todo check with your gateway on the parameters to pass
       * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#gateway|PaymentMethodTokenizationSpecification}
       */
      tokenizationSpecification: {
        type: "PAYMENT_GATEWAY",
        parameters: {
          gateway: "liqpay",
          gatewayMerchantId: "i39591921085",
        },
      },
      /**
       * Card networks supported by your site and your gateway
       *
       * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#CardParameters|CardParameters}
       * @todo confirm card networks supported by your site and gateway
       */
      allowedCardNetworks: [
        "MASTERCARD",
        "VISA",
      ],
      /**
       * Card authentication methods supported by your site and your gateway
       *
       * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#CardParameters|CardParameters}
       * @todo confirm your processor supports Android device tokens for your
       * supported card networks
       */
      allowedCardAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
      merchantInfo: {
        merchantId: 'BCR2DN4TVCVN7BJW',
        merchantName: "RIA.com",
      },
      transactionInfo: {
        countryCode: "UA",
        currencyCode: "UAH",
        totalPriceStatus: "FINAL",
        totalPrice: "0.00",
      }
    },
    // https://gitlab.decluster.ria.com/bu-auto-services/billing-service#gpay
    paymentTypeId: 0,
    paymentCost: 0,
  }),
  watch: {
    totalPrice() {
      this.getGooglePayBtnInitData();
    }
  },
  mounted() {
    this.getGooglePayBtnInitData();
    setTimeout(() => this.loadGooglePay(), 2000);
  },
  computed: {
    ...mapGetters({
      isDesktop: 'shared/device/isDesktop',
    }),
  },
  methods: {
    async getGooglePayBtnInitData () {
      try {
        const body = {
          type: "google_pay",
          services: [{
            name: 'donation',
            donation: this.totalPrice
          }],
        }
        const res = await _laxios.getPaymentData.request({ data: { ...body } })
            .then((response) => response)
            .catch((e) => { console.error('getPaymentData', e.message)});

        if (res?.data) {
          this.config.baseRequest = res?.data?.baseRequest;
          this.config.tokenizationSpecification = res?.data?.tokenizationSpecification;
          this.config.allowedCardNetworks = res?.data?.baseCardPaymentMethod?.parameters?.allowedCardNetworks;
          this.config.allowedCardAuthMethods = res?.data?.baseCardPaymentMethod?.parameters?.allowedCardAuthMethods;
          this.config.transactionInfo = res?.data?.transactionInfo;
          this.config.merchantInfo = res?.data?.merchantInfo;

          this.paymentTypeId = res?.paymentType;
          this.paymentCost = res?.sum;
        }
      } catch (e) {
        console.error('getPaymentData', e);
      }
    },
    loadGooglePay() {
      /**
       * Describe your site's support for the CARD payment method and its required
       * fields
       *
       * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#CardParameters|CardParameters}
       */
      const baseCardPaymentMethod = () => ({
        type: "CARD",
        parameters: {
          allowedAuthMethods: this.config.allowedCardAuthMethods,
          allowedCardNetworks: this.config.allowedCardNetworks,
        },
      });

      /**
       * Describe your site's support for the CARD payment method including optional
       * fields
       *
       * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#CardParameters|CardParameters}
       */
      // пробуєм пофіксити merchantId
      const cardPaymentMethod = () => Object.assign({}, baseCardPaymentMethod(), {
        tokenizationSpecification: this.config.tokenizationSpecification,
      });

      /**
       * An initialized google.payments.api.PaymentsClient object or null if not yet set
       *
       * @see {@link getGooglePaymentsClient}
       */
      let paymentsClient = null;

      /**
       * Configure your site's support for payment methods supported by the Google Pay
       * API.
       *
       * Each member of allowedPaymentMethods should contain only the required fields,
       * allowing reuse of this base request when determining a viewer's ability
       * to pay and later requesting a supported payment method
       *
       * @returns {object} Google Pay API version, payment methods supported by the site
       */
      const getGoogleIsReadyToPayRequest = () => {
        return Object.assign({}, this.config.baseRequest, {
          allowedPaymentMethods: [baseCardPaymentMethod()],
        });
      };

      /**
       * Configure support for the Google Pay API
       *
       * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#PaymentDataRequest|PaymentDataRequest}
       * @returns {object} PaymentDataRequest fields
       */
      const getGooglePaymentDataRequest = () => {
        const paymentDataRequest = Object.assign({}, this.config.baseRequest);
        paymentDataRequest.allowedPaymentMethods = [cardPaymentMethod];
        paymentDataRequest.allowedPaymentMethods = [cardPaymentMethod()];
        paymentDataRequest.transactionInfo = getGoogleTransactionInfo();
        // @todo a merchant ID is available for a production environment after approval by Google
        // See {@link https://developers.google.com/pay/api/web/guides/test-and-deploy/integration-checklist|Integration checklist}
        paymentDataRequest.merchantInfo = this.config.merchantInfo;
        return paymentDataRequest;
      };

      /**
       * Return an active PaymentsClient or initialize
       *
       * @see {@link https://developers.google.com/pay/api/web/reference/client#PaymentsClient|PaymentsClient constructor}
       * @returns {google.payments.api.PaymentsClient} Google Pay API client
       */
      const mode = process.env.NODE_ENV;
      const isProduction = mode === 'production';

      function getGooglePaymentsClient() {
        if (paymentsClient === null) {
          paymentsClient = new google.payments.api.PaymentsClient({
            // Alterar o environment para 'PRODUCTION' em prod
            environment: isProduction ? 'PRODUCTION' : 'TEST',
          });
        }
        return paymentsClient;
      }

      /**
       * Initialize Google PaymentsClient after Google-hosted JavaScript has loaded
       *
       * Display a Google Pay payment button after confirmation of the viewer's
       * ability to pay.
       */
      function onGooglePayLoaded() {
        const paymentsClient = getGooglePaymentsClient();
        paymentsClient
        .isReadyToPay(getGoogleIsReadyToPayRequest())
        .then(function (response) {
          if (response.result) {
            addGooglePayButton();
            // @todo prefetch payment data to improve performance after confirming site functionality
            // prefetchGooglePaymentData();
          }
        })
        .catch(function (err) {
          // show error in developer console for debugging
          console.error(err);
        });
      }

      /**
       * Add a Google Pay purchase button alongside an existing checkout button
       *
       * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#ButtonOptions|Button options}
       * @see {@link https://developers.google.com/pay/api/web/guides/brand-guidelines|Google Pay brand guidelines}
       */
      const addGooglePayButton = () => {
        const paymentsClient = getGooglePaymentsClient();
        const button = paymentsClient.createButton({
          buttonColor: 'black',
          buttonType: 'plain',
          buttonLocale: 'uk',
          buttonSizeMode: 'fill',
          buttonRadius: 8,
          onClick: onGooglePaymentButtonClicked,
        });
        this.$refs.googlePay.appendChild(button);
      };

      /**
       * Provide Google Pay API with a payment amount, currency, and amount status
       *
       * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#TransactionInfo|TransactionInfo}
       * @returns {object} transaction info, suitable for use as transactionInfo property of PaymentDataRequest
       */
      const getGoogleTransactionInfo = () => ({
        countryCode: "UA",
        currencyCode: "UAH",
        totalPriceStatus: "FINAL",
        // set to cart total
        totalPrice: this.totalPrice,
      });

      /**
       * Prefetch payment data to improve performance
       *
       * @see {@link https://developers.google.com/pay/api/web/reference/client#prefetchPaymentData|prefetchPaymentData()}
       */
      // function prefetchGooglePaymentData() {
      //   const paymentDataRequest = getGooglePaymentDataRequest();
      //   // transactionInfo must be set but does not affect cache
      //   paymentDataRequest.transactionInfo = {
      //     totalPriceStatus: "NOT_CURRENTLY_KNOWN",
      //     currencyCode: "UAH",
      //   };
      //   const paymentsClient = getGooglePaymentsClient();
      //   paymentsClient.prefetchPaymentData(paymentDataRequest);
      // }

      /**
       * Show Google Pay payment sheet when Google Pay payment button is clicked
       */
      const onGooglePaymentButtonClicked = () => {
        const paymentDataRequest = getGooglePaymentDataRequest();
        paymentDataRequest.transactionInfo = getGoogleTransactionInfo();

        const paymentsClient = getGooglePaymentsClient();
        paymentsClient
        .loadPaymentData(paymentDataRequest)
        .then((paymentData) => {
          // handle the response
          const paymentToken = processPayment(paymentData);
          if (paymentToken) {
            const data = {
              services: [
                {
                  name: 'donation',
                  donation: this.totalPrice,
                },
              ],
              paymentMethod: 'GPay',
              paymentData: paymentToken,
              paymentTypeId: this.paymentTypeId,
              paymentCost: this.paymentCost,
            };
            return _laxios.makeOrder
            .request({data})
            .then((response) => {
              if (response?.payData?.completeData?.redirect_url) {
                window.location = response?.payData?.completeData?.redirect_url;
                this.showCongratsPopup();
              }
              return response;
            })
            .catch((e) => {
              console.error('makeOrder', e.message);
            });
          }
        })
        .catch(function (err) {
          // show error in developer console for debugging
          console.error(err);
        });
      }

      /**
       * Process payment data returned by the Google Pay API
       *
       * @param {object} paymentData response from Google Pay API after user approves payment
       * @see {@link https://developers.google.com/pay/api/web/reference/response-objects#PaymentData|PaymentData object reference}
       */
      function processPayment(paymentData) {
        // show returned data in developer console for debugging
        // @todo pass payment token to your gateway to process payment
        const paymentToken = paymentData?.paymentMethodData?.tokenizationData?.token;
        return Buffer.from(paymentToken).toString('base64');
      }

      onGooglePayLoaded();
    },
  },
};
</script>
