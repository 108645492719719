export default {
    props: {
        data: Object,
        default: null
    },
    mixins: [require('../../../mixins/templateEngine')],
    computed: {
        header() {
            return this?.data?.header;
        },
        donationInfo() {
            return this?.data?.donation?.info;
        },
        donationPay() {
            return this?.data?.donation?.pay;
        },
        share() {
            return this?.data?.share;
        },
    },
    components: {
        'Sharelink': () => import('./Sharelink/index.vue'),
        'DonationInfo': () => import('./DonationInfo/index.vue'),
        'FormDonation': () => import('./FormDonation/index.vue'),
        'Statistic': () => import('./Statistic/index.vue'),
    },
};

