export default {
	props: {
		data: Object,
		default: null
	},
	mixins: [require('../../../mixins/templateEngine')],
	data() {
		return {
			isLoaded: false,
		}
	},
	methods: {
		onImgLoad () {
			this.isLoaded = true;
		}
	},
};

