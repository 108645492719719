import {mapMutations} from "vuex";

export default {
	name: 'Answers',
	props: {
		data: Object,
		default: null
	},
	mixins: [require('../../../mixins/templateEngine')],
	methods: {
		...mapMutations({
			donationsLoggerData: 'donation/donationsLoggerData',
		}),
	},
};
